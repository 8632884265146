;var App = {
    timer: null, 
    rotorUrl: '/rotor/attendant/random',
    xhr: null,
    flipSlide: function(elm) {
        var self = this;
        
        if('true' === elm.getAttribute('data-rotor')) { 
            elm.setAttribute('data-rotor', 'false');
            let url = this.rotorUrl;
            var xhr = this.xhr;
                        
            xhr.onreadystatechange = function() {
                if (4 == this.readyState && 200 == this.status) {
                    try {
                        var json = JSON.parse( this.responseText ); 
                        if (false == json.success) {
                            console.log("Failed to load slide: " + json.error);
                            return;
                        } 
                        
                        var data = json.data;
                        document.getElementById('attendant-rotor-link').setAttribute('href', '/attendants/' + data.nickName);
                        document.getElementById('attendant-rotor-img').setAttribute('src', '/media/attendant/' + data.image);
                        document.getElementById('attendant-rotor-nickName').innerText = data.nickName;
                        
                        elm.setAttribute('data-rotor', 'true');                        
                    } catch(err) {
                        console.log("Failed to load slide: " + err.message);
                    }
                } else if (4 == this.readyState && 0 != this.status) {
                    console.log("Failed to load slide: " + this.txtStatus);
                }
            };
            
            xhr.onerror = function() { // status other than 200
                console.log("Faild to load slide" + this.txtStatus);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.send();
            
        }        
    },
    init: function() {
        var self = this;
        var pageContent = document.getElementById('page_content');
        
        this.xhr = new XMLHttpRequest();
        
        // rotor
//        [].forEach.call(pageContent.querySelectorAll('.side-rotor'), function(el) {             
//            if('true' === el.getAttribute('data-rotor')) {  
//                setInterval(function() {
//                    self.flipSlide(el);
//                }, 5000);
//                
//                self.flipSlide(el);
//            }
//        });
        
        // thumbnail for attendant page
        if (document.getElementById('thumbnails')) {
            [].forEach.call(document.getElementById('thumbnails').querySelectorAll('img'), function(el) {             
                el.addEventListener('click', function(e) { 
                    document.getElementById('thumbnails').querySelector('.is-active').classList.remove('is-active');
                    el.parentNode.classList.add('is-active');
                    document.getElementById('image').setAttribute('src', el.getAttribute('src'));
                });
            });
        }
    }
};

document.addEventListener('DOMContentLoaded', function() {
    App.init();
});
